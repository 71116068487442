<template>
	<!-- eligibility -->
	<div v-if="eligibility" class="icon-message">
		<FontAwesomeIcon v-if="icon" :class="className" :icon="icon" aria-hidden="true" />
		<div v-if="webCode === '3' && !isSBC">
			<BLink :href="$t('link')" target="_blank"
				>{{ eligibility }}
				<FontAwesomeIcon :icon="['fal', 'external-link-alt']" icon-size="sm" aria-hidden="true"
			/></BLink>
		</div>
		<div v-else>{{ eligibility }}</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { BRow, BCol, BLink } from 'bootstrap-vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';

@Component({
	name: 'DrugEligibility',
	components: {
		BRow,
		BCol,
		BLink
	},
	mixins: [IdMixin],
	props: {
		eligibility: {
			type: String,
			required: true
		},
		webCode: {
			type: String,
			required: true
		}
	}
})
export default class DrugEligibility extends Vue {
	className = null;
	icon = null;
	isSBC = false;

	drugEligibility() {
		switch (this.webCode) {
			case '1':
				this.className = 'benefit-yes';
				this.icon = ['fas', 'check-circle'];
				break;
			case '2':
			case '4':
			case '7':
			case '9':
				this.className = 'benefit-no';
				this.icon = ['fas', 'times-circle'];
				break;
			case '3':
			default:
				this.className = 'benefit-special';
				this.icon = ['fas', 'exclamation-circle'];
		}
	}

	async created() {
		this.isSBC = (await JSON.parse(sessionStorage.getItem('securityPolicy')).brand) === 'SBC';
		this.drugEligibility();
	}
}
</script>
<style lang="scss" scoped>
.benefit-yes {
	color: $blue;
	font-size: 15px;
	margin-right: 7px;
}

.benefit-no {
	color: #d3080c;
	font-size: 15px;
	margin-right: 7px;
}

.benefit-special {
	color: #f78e1e;
	font-size: 15px;
	margin-right: 7px;
}

.icon-message {
	display: flex;
	margin-left: -17px;
}
</style>
<i18n>
{
  "en": {
		"link":"https://www.medaviebc.ca/en/members/your-coverage/prior-authorization-requests"
    },
    "fr": {
		"link":"https://www.medaviebc.ca/fr/adherents/votre-couverture/demande-autorisation-prealable"
    }
}
</i18n>
