<template>
	<BaseAccordion automation-id="drug-coverage">
		<DrugsAccordionPanel
			v-for="(result, i) in drugs"
			:id="`bcollapse-${i}`"
			:key="i"
			:index="i"
			:result="result"
			group-name="drugCoverageResult"
			:button-label="getName(result.drugName)"
			border
			automation-id="drug-coverage"
			:is-from-drug-page="isFromDrugPage"
			:selected-index="selectedIndex"
			:index-to-show="indexToShow"
			@show="showDrugDetails(result.drugDin, i)"
		>
			<template :id="i" #additional-text>
				<p class="din mb-3 ml-3">{{ $t('din') }}{{ result.drugDin }}</p>
			</template>
			<DrugsIconsAndMessages
				v-if="drugDetails"
				class="drug-message pb-2 ml-2"
				automation-id="drug-coverage"
				:drugs="drugDetails"
			/>
			<BRow class="button-row">
				<BaseButton
					v-if="
						drugDetails &&
						drugDetails.genericEquivalents &&
						drugDetails.genericEquivalents.length > 0
					"
					:label="$t('button.generic')"
					automation-id="generic-equivalents"
					class="generic-button mt-3"
					@click="
						toGenericEquivalents(
							drugDetails.genericEquivalents,
							drugDetails.drugName,
							province,
							participant,
							i,
							drugDetails.drugDin
						)
					"
				/>
				<BaseButton
					v-if="
						drugDetails &&
						drugDetails.comparableDrugs &&
						Object.keys(drugDetails.comparableDrugs).length > 0
					"
					:label="$t('button.comparable')"
					class="comparable-button mt-3"
					automation-id="comparable-drugs"
					@click="
						toComparableDrugs(
							drugDetails.comparableDrugs,
							drugDetails.drugName,
							province,
							participant,
							i,
							drugDetails.drugDin
						)
					"
				/>
				<span></span>
			</BRow>
		</DrugsAccordionPanel>
	</BaseAccordion>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseAccordion from '@/components/common/base/BaseAccordion';
import BaseAccordionPanel from '@/components/common/base/BaseAccordionPanel';
import DrugsIconsAndMessages from './DrugsIconsAndMessages.vue';
import BaseButton from '@/components/common/base/BaseButton';
import { COVERAGE } from '@/constants/Routes';
import { titleCase } from '@/utils/titleCase';
import DrugsAccordionPanel from './DrugsAccordionPanel.vue';
import DrugCoverageGeneric from '@/models/coverage/DrugCoverageGeneric.js';
import { BRow } from 'bootstrap-vue';

@Component({
	name: 'DrugsSearchResult',
	components: {
		BaseAccordion,
		DrugsIconsAndMessages,
		BaseButton,
		DrugsAccordionPanel,
		BaseAccordionPanel,
		BRow
	},
	mixins: [IdMixin],
	props: {
		drugs: {
			type: Array,
			required: true
		},
		province: {
			type: String,
			default: null
		},
		participant: {
			type: Object,
			required: true
		},
		isFromDrugPage: {
			type: Boolean,
			required: false
		},
		indexToShow: {
			type: Number,
			default: null
		},
		dinToShow: {
			type: String,
			default: null
		}
	},
	watch: {
		locale() {
			if (this.isDrugSelected) {
				this.showDrugDetails(this.selectedDin);
			}
		}
	}
})
export default class DrugsSearchResult extends Vue {
	drugDetails = {};
	result = [];
	selectedDin = null;
	selectedIndex = null;
	isDrugSelected = false;

	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	async created() {
		if (this.isFromDrugPage) {
			this.showDrugDetails(this.dinToShow);
		}
	}

	async showDrugDetails(din, i) {
		this.isDrugSelected = true;
		this.selectedDin = din;
		this.selectedIndex = i;
		this.drugDetails = {};
		let drugDe = {
			participant: this.participant.participant,
			drugDin: din,
			drugs: this.drugs,
			provinceOfAdjudication: this.province,
			dinOrName: this.participant.drugNameOrDin,
			index: this.selectedIndex
		};

		//this api call returns the details of a drug when the accordion panel is expanded for the same.
		this.drugDetails = await DrugCoverageGeneric.getGrugCoverageDetail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			drugDe
		);
	}

	toGenericEquivalents(genericEquivalents, drugName, province, participant, i, drugDin) {
		this.$router.push({
			name: COVERAGE.GENERIC_EQUIVALENT,
			params: {
				genericEquivalents: genericEquivalents,
				drugName: drugName,
				province: province,
				participant: participant.participant,
				drugOrDin: participant.drugNameOrDin,
				drugs: this.drugs,
				index: i,
				drugDin: drugDin
			}
		});
	}

	toComparableDrugs(comparableDrugs, drugName, province, participant, i, drugDin) {
		this.$router.push({
			name: COVERAGE.COMPARABLE_DRUGS,
			params: {
				comparableDrugs: comparableDrugs,
				drugName: drugName,
				province: province,
				drugDin: drugDin,
				participant: participant.participant,
				drugOrDin: participant.drugNameOrDin,
				drugs: this.drugs,
				index: i
			}
		});
	}

	getName(text) {
		return titleCase(text);
	}
}
</script>

<style lang="scss" scoped>
.accordion {
	border-radius: 0px;
	& ::v-deep :last-child {
		border: none;
	}
}
::v-deep .accordion-content {
	padding: 0 15px;
}

.din {
	margin-top: -15px;
	font-size: 15px;
	font-family: $lato-font-family;
	position: relative;
}

.drug-message {
	font-size: 15px;
	font-family: $josefin-font-family;
	width: 100%;
}

.button-row {
	padding-bottom: 30px;
	padding-left: 7px;
	margin-top: -20px;
}

.generic-button {
	margin-right: 10px;
}
</style>

<i18n>
{
    "en": {
		"din":"DIN: ",
		"button":{
			"generic":"View generic equivalents",
			"comparable":"View comparable drugs"
		}
    },
    "fr": {
		"din":"DIN: ",
		"button":{
			"generic":"Voir les équivalents génériques",
			"comparable":"Voir les médicaments similaires"
		}
    }
}
</i18n>
