<template>
	<span>
		<BLink
			:id="getUniqueId(`text-modal-link${idIncrement}`)"
			class="link"
			href="#"
			@click="toggleModal(true)"
		>
			{{ text }}
		</BLink>

		<BaseModal
			:id="getUniqueId('textModal')"
			:modal-title="modalTitle"
			:is-static="true"
			:modal-show="modalShow"
			css-dialog-class="textModalDialog"
			:automation-id="getAutomationId('text-modal')"
			:size="size"
			@change="toggleModal"
		>
			<template #contentBody>
				<slot></slot>
				<BaseButton
					v-if="showOk"
					variant="primary"
					label="OK"
					class="d-block mt-3"
					pill
					:automation-id="getAutomationId('ok-button')"
					@click="toggleModal(false)"
				/>
			</template>
		</BaseModal>
	</span>
</template>

<script>
/**
 * This component displays a text model.
 * Instead of using a button to activate the modal this component uses text.
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseModal from '@/components/common/base/BaseModal.vue';
import BaseButton from './base/BaseButton.vue';
import { BLink } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'TextModal',
	components: {
		BaseModal,
		BLink,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		id: {
			type: String,
			default: 'textModal'
		},
		/**
		 * This prop assigns the Title to be displayed in the modal
		 */
		modalTitle: {
			type: String,
			default: ''
		},
		/**
		 * value to be displayed in the body of the modal.
		 */
		text: {
			type: String,
			default: null,
			required: true
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		},
		idIncrement: {
			type: String,
			default: ''
		},
		showOk: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'lg'
		}
	}
})
export default class TextModal extends Vue {
	toggleModalShow = false;

	get modalShow() {
		return this.toggleModalShow;
	}

	toggleModal(isVisible) {
		this.toggleModalShow = isVisible;
	}
}
</script>

<style lang="scss" scoped>
.link {
	border-bottom: 1px dotted;
	text-decoration: none;
}
.link:hover {
	border-bottom: 1px solid;
	text-decoration: none;
	color: $dark-blue;
}
</style>
