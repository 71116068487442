<template>
	<div>
		<BaseButton
			:id="getUniqueId('modalButton')"
			ref="triggerButton"
			class="thebtn"
			:label="buttonLabel"
			:pill="false"
			variant="link"
			:automation-id="getAutomationId('infomodalbutton')"
			:icon="buttonIcon"
			type="button"
			:aria-label="ariaLabel"
			:icon-padding="buttonPadding"
			@click="open"
		>
		</BaseButton>

		<BaseModal
			:id="getUniqueId('infoModal')"
			ref="infoModal"
			:size="size"
			:modal-title="modalTitle"
			:modal-show="modalShow"
			:is-static="isStatic"
			:css-dialog-class="dialogClass"
			:css-header-class="headerClass"
			:css-body-class="bodyClass"
			:css-content-class="contentClass"
			:automation-id="getAutomationId('infomodal')"
			@change="change"
		>
			<template #contentBody>
				<slot></slot>
				<BaseButton
					v-if="showOk"
					variant="primary"
					:label="$t('ok')"
					pill
					:automation-id="getAutomationId('infomodal-ok-button')"
					@click="change(false)"
				/>
			</template>
		</BaseModal>
	</div>
</template>

<script>
/**
 * This component displays a model.
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseModal from '@/components/common/base/BaseModal.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
// @vue/component
@Component({
	name: 'InfoModal',
	components: {
		BaseButton,
		BaseModal
	},
	mixins: [IdMixin],
	props: {
		/**
		 * This prop assigns the Title to be displayed in the modal
		 */
		modalTitle: {
			type: String,
			default: ''
		},
		buttonIcon: {
			type: Array,
			default: () => ['fal', 'question-circle']
		},
		/** boolean to tell if the modal is static or not. If false parent components css cann't change the modal. */
		isStatic: {
			type: Boolean,
			default: false
		},
		/** props to set css classes to different parts of the modal. This can be targeted from a parent component.  */
		dialogClass: {
			type: String,
			default: null
		},
		headerClass: {
			type: String,
			default: null
		},
		bodyClass: {
			type: String,
			default: null
		},
		contentClass: {
			type: String,
			default: null
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		},
		showOk: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'lg'
		},
		buttonLabel: {
			type: String,
			default: null
		},
		buttonPadding: {
			type: Boolean,
			default: true
		}
	}
})
export default class InfoModal extends Vue {
	toggleModalShow = false;

	get modalShow() {
		return this.toggleModalShow;
	}

	open() {
		this.toggleModalShow = true;
	}

	change(isVisible) {
		this.toggleModalShow = isVisible;
	}

	ariaLabel = 'more information on' + this.modalTitle;
}
</script>

<style lang="scss" scoped>
.thebtn {
	margin-top: -5px;
	padding: 0px;
	font-size: 18px;
	line-height: 0px;
	font-weight: 300;
	min-width: 0px;
	word-wrap: break-word;
	border: none;
	&.btn-primary {
		/* Font Awesome icon color */

		&:hover,
		&:focus {
			border: 1px solid $dark-blue;
			background-color: $dark-blue;
			color: $white;
			box-shadow: none;
		}
	}

	&.btn-link {
		color: $blue;
	}
}
.svg-icon *:not(g) {
	fill: red;
}
.thebtn ::v-deep p {
	padding: 0px;
	margin-bottom: 0;
}
</style>

<i18n lang="json">
{
	"en": {
		"ok": "OK"
	},
	"fr": {
		"ok": "OK"
	}
}
</i18n>
