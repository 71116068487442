var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseAccordion',{attrs:{"automation-id":"drug-coverage"}},_vm._l((_vm.drugs),function(result,i){return _c('DrugsAccordionPanel',{key:i,attrs:{"id":`bcollapse-${i}`,"index":i,"result":result,"group-name":"drugCoverageResult","button-label":_vm.getName(result.drugName),"border":"","automation-id":"drug-coverage","is-from-drug-page":_vm.isFromDrugPage,"selected-index":_vm.selectedIndex,"index-to-show":_vm.indexToShow},on:{"show":function($event){return _vm.showDrugDetails(result.drugDin, i)}},scopedSlots:_vm._u([{key:"additional-text",fn:function(){return [_c('p',{staticClass:"din mb-3 ml-3"},[_vm._v(_vm._s(_vm.$t('din'))+_vm._s(result.drugDin))])]},proxy:true}],null,true)},[(_vm.drugDetails)?_c('DrugsIconsAndMessages',{staticClass:"drug-message pb-2 ml-2",attrs:{"automation-id":"drug-coverage","drugs":_vm.drugDetails}}):_vm._e(),_c('BRow',{staticClass:"button-row"},[(
					_vm.drugDetails &&
					_vm.drugDetails.genericEquivalents &&
					_vm.drugDetails.genericEquivalents.length > 0
				)?_c('BaseButton',{staticClass:"generic-button mt-3",attrs:{"label":_vm.$t('button.generic'),"automation-id":"generic-equivalents"},on:{"click":function($event){return _vm.toGenericEquivalents(
						_vm.drugDetails.genericEquivalents,
						_vm.drugDetails.drugName,
						_vm.province,
						_vm.participant,
						i,
						_vm.drugDetails.drugDin
					)}}}):_vm._e(),(
					_vm.drugDetails &&
					_vm.drugDetails.comparableDrugs &&
					Object.keys(_vm.drugDetails.comparableDrugs).length > 0
				)?_c('BaseButton',{staticClass:"comparable-button mt-3",attrs:{"label":_vm.$t('button.comparable'),"automation-id":"comparable-drugs"},on:{"click":function($event){return _vm.toComparableDrugs(
						_vm.drugDetails.comparableDrugs,
						_vm.drugDetails.drugName,
						_vm.province,
						_vm.participant,
						i,
						_vm.drugDetails.drugDin
					)}}}):_vm._e(),_c('span')],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }