<template>
	<BaseCard :automation-id="getAutomationId()">
		<BRow>
			<BCol v-if="isLoading" cols="12" class="text-center">
				<FontAwesomeIcon
					spin
					class="loading-icon text-center"
					:icon="['fas', 'spinner']"
				></FontAwesomeIcon>
			</BCol>
			<BCol v-if="!isLoading" cols="12">
				<p class="mb-0">{{ coverageDetails }}</p>
			</BCol>
		</BRow>
	</BaseCard>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard';
import { BRow, BCol } from 'bootstrap-vue';
import DrugCoverage from '@/models/coverage/DrugCoverage';

@Component({
	name: 'SuppressedDrugCoverageCard',
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			required: true
		}
	},
	components: {
		BaseCard,
		BCol,
		BRow
	},
	watch: {
		locale() {
			this.loadSuppressedDetail();
		}
	}
})
export default class SuppressedDrugCoverageCard extends Vue {
	isLoading = false;
	coverageDetails = '';

	get locale() {
		return this.$store.state.i18n.locale;
	}

	mounted() {
		this.loadSuppressedDetail();
	}

	async loadSuppressedDetail() {
		this.isLoading = true;
		this.coverageDetails = '';
		const response = await DrugCoverage.getSuppressedDrugDetails(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
		response.forEach((content) => {
			this.coverageDetails += content;
		});
		this.isLoading = false;
	}
}
</script>
<style lang="scss" scoped>
.loading-icon {
	color: $blue;
}
</style>
