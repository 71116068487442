<template>
	<BaseForm ref="drug-coverage-search-form" @keydown.enter="onClick" @submit="onClick">
		<BRow>
			<BCol cols="12" lg="4" sm="12">
				<BaseSelect
					:label="$t('label.fieldLabel1')"
					class="select-participant"
					:value="searchParams.participant"
					:validation-rules="{ required: true }"
					:automation-id="getAutomationId('participant')"
					:options="participants"
					@change="onChangeParticipant"
				/>
			</BCol>
			<BCol cols="12" lg="5" sm="12">
				<BaseInput
					:label="$t('label.fieldLabel2')"
					:value="searchParams.drugNameOrDin"
					type="search"
					class="search-input"
					automation-id="drug-name-or-din"
					:min-length="min"
					:max-length="max"
					:validation-rules="{ required: true, min: min, alphaCharacters: true }"
					:validation-messages="{
						required: $t('error.required'),
						min: $t('error.minimum'),
						alphaCharacters: $t('error.alphaNum')
					}"
					@input="updateDrugNameOrDin"
				>
				</BaseInput>
			</BCol>
			<BCol cols="12" lg="3" sm="12">
				<BaseButton
					:class="isEnglish ? 'search-button' : 'search-button-fr'"
					:icon="['fal', 'search']"
					variant="primary"
					:label="$t('button.search')"
					icon-position="left"
					icon-size="sm"
					:automation-id="getAutomationId('search')"
					type="submit"
				></BaseButton>
			</BCol>
		</BRow>
	</BaseForm>
</template>

<script>
import Vue from 'vue';
import { BRow, BCol } from 'bootstrap-vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseForm from '@/components/common/base/BaseForm';
import BaseSelect from '@/components/common/base/BaseSelect';
import BaseInput from '@/components/common/base/BaseInput.vue';
import { min } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('min', min);
extend('alphaCharacters', {
	validate: (value) => {
		const regex = /^[0-9A-Za-zàâçéèêëîïôûùüÿñæœ¿'.\s\d-]+$/i;
		return regex.test(value);
	},
	message: '{_field_} input cannot have special characters.'
});

@Component({
	name: 'SearchDrugCoverage',
	components: {
		BRow,
		BCol,
		BaseButton,
		BaseForm,
		BaseSelect,
		BaseInput
	},
	mixins: [IdMixin],
	props: {
		participants: {
			type: Array,
			required: true
		},
		min: {
			type: Number,
			default: 3
		},
		max: {
			type: Number,
			default: 30
		},
		searchParams: {
			type: Object,
			required: true
		}
	}
})
export default class SearchDrugCoverage extends Vue {
	async onClick() {
		this.$emit('click', this.searchParams);
	}

	onChangeParticipant(participant) {
		this.searchParams.participant = participant;
	}

	updateDrugNameOrDin(drugNameOrDin) {
		this.searchParams.drugNameOrDin = drugNameOrDin;
		this.$emit('input', drugNameOrDin);
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}
}
</script>

<style lang="scss" scoped>
.search-button {
	font-family: 'Josefin Sans', sans-serif;
	font-size: $body-regular-button-font-size;
	margin-top: 23px;
	@media (max-width: 1199px) {
		margin-top: 23px;
	}
	@media (max-width: 992px) {
		margin-top: 0px;
	}
}

.search-button-fr {
	font-family: 'Josefin Sans', sans-serif;
	font-size: $body-regular-button-font-size;
	margin-top: 23px;
	@media (max-width: 1199px) {
		margin-top: 45px;
	}
	@media (max-width: 992px) {
		margin-top: 0px;
	}
}

.form-group ::v-deep label {
	line-height: 1.33;
}
</style>

<i18n>
{
    "en": {
		"button": {
			"search": "Search"
		},
		"label": {
			"fieldLabel1": "Who is the drug for?",
      "fieldLabel2":"Enter the drug name or DIN"
		},
		"error": {
			"required":"A drug name is required to perform a search.",
			"minimum":"Drug names must be at least 3 characters to perform a search.",
	  	"alphaNum": "Oops, it seems you've entered an invalid character. Please review and try again."
    }
    },
    "fr": {
		"button": {
			"search": "Rechercher"
		},
			"label": {
			"fieldLabel1": "Pour qui est ce médicament?",
      "fieldLabel2":"Entrer le nom du médicament ou son DIN"
		},
		  "error": {
			"required":"Vous devez entrer un nom de médicament pour effectuer une recherche.",
			"minimum":"Entrez au moins 3 caractères pour effectuer une recherche.",
	  	"alphaNum": "Oups! Il semble que vous ayez entré un caractère invalide. Veuillez vérifier et essayer de nouveau."
    }
    }
}
</i18n>
