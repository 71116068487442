<template>
	<BaseCard v-bind="$attrs" class="accordion" :automation-id="getAutomationId('accordion')">
		<slot> </slot>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard';

// @vue/component
@Component({
	name: 'BaseAccordion',
	inheritedAttrs: false,
	components: {
		BaseCard
	},
	mixins: [IdMixin]
})
export default class BaseAccordion extends Vue {}
</script>
