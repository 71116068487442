<!--
  Page template to be use for all coverage pages.
-->
<template>
	<div class="mt-3 mt-sm-4">
		<BRow v-if="pageTitle">
			<BCol>
				<h1 :class="pageSubTitle ? 'mb-2' : 'mb-3'">{{ pageTitle }}</h1>
			</BCol>
		</BRow>
		<BRow v-if="pageSubTitle">
			<BCol>
				<h2 class="mb-3">{{ pageSubTitle }}</h2>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" :lg="fullWidth ? '12' : '10'">
				<slot></slot>
			</BCol>
		</BRow>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BRow, BCol } from 'bootstrap-vue';

export default {
	name: 'CoverageTemplate',
	components: { BRow, BCol },
	mixins: [IdMixin],
	props: {
		pageTitle: {
			type: String,
			default: null
		},
		pageSubTitle: {
			type: String,
			default: null
		},
		fullWidth: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
h2 {
	font-size: 18px;
	line-height: normal;
}
</style>
