<template>
	<CoverageTemplate :page-title="$t('title')" full-width>
		<BRow class="mb-4">
			<BCol lg="10" md="12">
				<BenefitsBookletDownloadCard
					:description="$t('booklet.description')"
					:download-link-name="$t('booklet.downloadLink')"
					automation-id="myCoverage"
				></BenefitsBookletDownloadCard>
				<SuppressedDrugCoverageCard
					v-if="isUserSuppressed"
					automation-id="drug-coverage-suppressed"
				/>
				<p class="h3">{{ $t('subtitle') }}</p>
				<p>{{ $t('text1') }}</p>
				<BaseCard>
					<SearchDrugCoverage
						v-if="searchParameters"
						:participants="participants"
						:search-params="searchParameters"
						automation-id="drug-coverage"
						@click="searchDrugAndGetData"
					/>
				</BaseCard>
				<div v-if="isSerchBtnClicked || isFromDrugPage">
					<div v-if="drugsFound && drugsFound.length > 0">
						<p class="results">{{ drugsFound.length }} {{ numberOfResults }}</p>
						<DrugsSearchResult
							automation-id="drug-coverage"
							:drugs="drugsFound"
							:province="provinceOfAdjudication"
							:participant="searchParameters"
							:is-from-drug-page="isFromDrugPage"
							:index-to-show="indexToShow"
							:din-to-show="dinToShow"
						/>
					</div>
					<div v-else>
						<div v-if="displayError">
							<h2 class="h3 mt-4">{{ $t('error.title') }}</h2>
							<p>{{ $t('error.text') }}</p>
						</div>
					</div>
				</div>
			</BCol>
		</BRow>
	</CoverageTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import CoverageTemplate from '../CoverageTemplate.vue';
import { BCol, BRow } from 'bootstrap-vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
import BenefitsBookletDownloadCard from '@/components/coverage/BenefitsBookletDownloadCard.vue';
import SuppressedDrugCoverageCard from '@/components/coverage/drugs/SuppressedDrugCoverageCard';
import SearchDrugCoverage from '@/components/coverage/drugs/SearchDrugCoverage';
import DrugsSearchResult from '@/components/coverage/drugs/DrugsSearchResult';
import Dependent from '@/models/Dependent.js';
import DrugCoverage from '@/models/coverage/DrugCoverage.js';
import MemberCardInfo from '@/models/MemberCardInfo';
import BaseButton from '@/components/common/base/BaseButton';

@Component({
	name: 'DrugPage',
	components: {
		CoverageTemplate,
		BCol,
		BRow,
		BaseCard,
		BenefitsBookletDownloadCard,
		SearchDrugCoverage,
		DrugsSearchResult,
		BaseButton,
		SuppressedDrugCoverageCard
	},
	mixins: [IdMixin, BreadcrumbsManager],

	computed: {
		locale() {
			return this.$root.$i18n.locale;
		}
	},
	watch: {
		locale: function () {
			/**
			 * When the locale changes the search drugs are displayed in the preferred language.
			 *the drug messages are retrieved from the api
			 */
			this.getDrugsCoverage(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale,
				this.searchParameters
			);
		}
	}
})
export default class DrugPage extends Vue {
	participants = [];
	drugsFound = [];
	member = [];
	provinceOfAdjudication = null;
	isSerchBtnClicked = false;
	showErrorModal = false;
	comparableDrugs = null;
	searchParameters = {
		participant: '01',
		drugNameOrDin: null,
		province: this.provinceOfAdjudication
	};
	isFromDrugPage = false;
	index = null;
	displayError = true;

	indexToShow = null;
	dinToShow = null;

	async beforeRouteEnter(to, from, next) {
		//checks if the user is coming back from generic equivalent or comparable page
		next((vm) => {
			if (from.name === COVERAGE.GENERIC_EQUIVALENT || from.name === COVERAGE.COMPARABLE_DRUGS) {
				vm.isFromDrugPage = true;
			}
			next();
		});
	}

	async mounted() {
		/**
		 * if the page is directed from generic equivalent or comparable page,
		 * the search details are still populated and the search results are still visible.
		 */

		if (this.isFromDrugPage) {
			if ('drugDin' in this.$route.params) {
				this.indexToShow = this.$route.params.index;
				this.dinToShow = this.$route.params.drugDin;
			} else {
				this.indexToShow = Number(sessionStorage.getItem('index'));
				this.dinToShow = sessionStorage.getItem('drugDin');
			}

			let params = null;
			params = await DrugCoverage.getSavedSearchParameters(sessionStorage.getItem('email'));
			this.drugsFound = await DrugCoverage.getSavedSearchResults(sessionStorage.getItem('email'));

			if (params) {
				this.searchParameters = {
					participant: params.participant,
					drugNameOrDin: params.drugNameOrDin,
					province: params.provinceOfAdjudication
				};
				this.provinceOfAdjudication = params.provinceOfAdjudication;
			} else {
				this.searchParameters = {
					participant: '01',
					drugNameOrDin: null,
					province: null
				};
				this.displayError = false;
			}
		}
	}

	async created() {
		this.getMembers();
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.drug', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.drug', 'fr') }
			]
		);
	}

	get isUserSuppressed() {
		const { suppressCoverage } = JSON.parse(sessionStorage.getItem('securityPolicy'));
		return suppressCoverage ? suppressCoverage : false;
	}

	async getMembers() {
		this.$store.dispatch('updateLoading', true);
		await Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			if (response?.data && response?.status === 200) {
				for (const index in response.data) {
					if (response.data[index].active) {
						this.participants.push({
							value: response.data[index].participantId,
							text: `${response.data[index].firstName} ${response.data[index].lastName}`
						});
					}
				}
			} else {
				this.$store.dispatch('error');
			}
		});
		this.$store.dispatch('updateLoading', false);
	}

	async searchDrugAndGetData(searchDrug) {
		this.isSerchBtnClicked = false;
		this.isFromDrugPage = false;
		this.displayError = true;

		if (searchDrug.drugNameOrDin && searchDrug.participant) {
			this.$store.dispatch('updateLoading', true);
			this.drugsFound = [];
			this.searchParameters = searchDrug;
			await this.getDrugsCoverage();
			await this.getMemberInfo();
			this.$store.dispatch('updateLoading', false);
			this.isSerchBtnClicked = true;
		}
	}

	get numberOfResults() {
		return this.drugsFound.length > 1 ? this.$t('resultsFound') : this.$t('resultFound');
	}

	async getDrugsCoverage() {
		this.drugsFound = await DrugCoverage.getDrugs(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			this.searchParameters
		);
	}

	async getMemberInfo() {
		this.member = await MemberCardInfo.getMemberDetail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
		this.provinceOfAdjudication = this.member.provinceOfAdjudication;
	}

	closeErrorModal() {
		this.showErrorModal = false;
	}
}
</script>
<style lang="scss" scoped>
.results {
	font-family: $josefin-font-family;
	font-size: 18px;
}
</style>
<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "myCoverage": "Plan Coverage",
			"drug": "Drugs"
    },
		"title": "Drug Coverage",
	  "booklet": {
      "description": "Download your benefits booklet to see your coverage, when and how to make a claim, and what percentage of the costs we'll cover.",
      "downloadLink":"Download your benefits booklet (PDF)"
    },
		"subtitle":"Search Drug Coverage",
		"text1":"Select a person on your plan and type the drug name or Drug Identification Number (DIN) to see what's covered.",
		"resultFound": "result found",
		"resultsFound": "result(s) found",
		"error": {
			"title": "We couldn't find anything matching your request.",
			"text": "Please try a new search."
		}
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "myCoverage": "Couverture du régime",
			"drug": "Médicaments"
    },
		"title": "Assurance médicaments",
    "booklet": {
      "description": "Téléchargez votre brochure de garanties pour découvrir votre couverture d’assurance, savoir quand et comment soumettre une demande de règlement et connaître les pourcentages des coûts qui sont couverts.",
      "downloadLink":"Téléchargez votre brochure de garanties (PDF)"
    },
		"subtitle":"Rechercher un médicament pour savoir s’il est couvert",
		"text1":"Sélectionnez une personne dans votre régime et tapez le nom du médicament ou le numéro d’identification de médicament (DIN) pour savoir s’il est couvert.",
		"resultFound": "résultat trouvé",
		"resultsFound": "résultat(s) trouvé(s)",
		"error": {
			"title": "Nous n’avons rien trouvé qui corresponde à votre demande.",
			"text": "Veuillez effectuer une nouvelle recherche."
		}
  }
}
</i18n>
