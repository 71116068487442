<template>
	<div>
		<BaseButton
			:id="getUniqueId('backButton')"
			class="pl-0 mr-3 mt-3 back-button"
			:automation-id="getAutomationId('backButton')"
			:label="$t('button.back')"
			:aria-label="$t('button.back')"
			:icon="['fal', 'chevron-left']"
			icon-position="left"
			icon-size="sm"
			variant="link"
			@click="navigateBack()"
		></BaseButton>
		<p class="mt-2 title">{{ $t('title') }}</p>
		<CoverageTemplate :page-title="getDrugName(drugName)" full-width>
			<BRow class="pb-4 mb-4">
				<BCol lg="10" md="12">
					<p>{{ $t('text1') }}</p>
					<BRow
						v-for="(drug, i) in resultArray"
						:key="i"
						class="ml-sm-0 mr-sm-0 generic-drug-table"
					>
						<BCol lg="6" md="12">
							<div class="drug-name mt-md-3 mt-sm-2">{{ drug.drugName }}</div>
							<div class="din">{{ $t('din') }}{{ drug.drugDin }}</div></BCol
						>
						<BCol lg="4" md="8" xs="6" class="eligibility">
							<DrugEligibility :eligibility="drug.eligibility" :web-code="drug.webCode"
						/></BCol>
						<BCol lg="2" md="4" xs="6" class="tier">
							<div v-if="drug.tier">
								<TextModal
									:text="getTier(drug.tier)"
									:modal-title="
										isTier1(drug.tier) ? $t('tier1Tooltip.title') : $t('tier2Tooltip.title')
									"
									:automation-id="getAutomationId('textModal')"
									:id-increment="`-tier-${i}`"
								>
									<div v-if="drug.tier === '1'">
										<p>{{ $t('tier1Tooltip.text') }}</p>
									</div>
									<div v-if="drug.tier === '2'">
										<p>{{ $t('tier2Tooltip.text') }}</p>
									</div></TextModal
								>
							</div></BCol
						>
					</BRow>
				</BCol>
			</BRow>
		</CoverageTemplate>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
import CoverageTemplate from '../CoverageTemplate.vue';
import { BCol, BRow } from 'bootstrap-vue';
import DrugCoverageGeneric from '@/models/coverage/DrugCoverageGeneric.js';
import DrugEligibility from '@/components/coverage/drugs/DrugEligibility';
import TextModal from '@/components/common/TextModal.vue';
import { titleCase } from '@/utils/titleCase';
import BaseButton from '@/components/common/base/BaseButton';

@Component({
	name: 'GenericEquivalentDrugsPage',
	components: {
		CoverageTemplate,
		BCol,
		BRow,
		DrugEligibility,
		TextModal,
		BaseButton
	},
	mixins: [IdMixin, BreadcrumbsManager],
	watch: {
		locale: function () {
			/**
			 * When the locale changes the search drugs are displayed in the preferred language.
			 *the drug messages are retrieved from the api
			 */
			this.genericEquivalents?.forEach((item) => {
				this.resultArray = [];
				this.getGenericDrugDetails(item.drugDin);
			});
		}
	}
})
export default class GenericEquivalentDrugsPage extends Vue {
	genericEquivalents = null;
	drugName = null;
	province = null;
	drugDin = null;
	genericDrugDetails = [];
	participant = null;
	resultArray = [];
	tier = null;
	drugOrDin = null;
	drugs = null;
	index = null;

	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	async mounted() {
		this.genericEquivalents = this.$route.params.genericEquivalents;
		this.drugName = this.$route.params.drugName;
		this.province = this.$route.params.province;
		this.participant = this.$route.params.participant;
		this.drugOrDin = this.$route.params.drugOrDin;
		this.drugs = this.$route.params.drugs;
		this.index = this.$route.params.index;
		this.drugDin = this.$route.params.drugDin;

		/**when a user clicks on the 'View generic equivalents' button on the accordion panel on the prev page (Drug search), the result shows an array of generic equivalents.
		 * The array consist of objects of generic drugs that are to be displayed on this page(Generic equivalents page) which does not have the 'eligibility' key and its value.
		 * So the drug din for each generic equvalent is selected and api is called to display the 'eligibility' on Generic equivalents page
		 * */

		this.genericEquivalents?.forEach((item) => {
			this.getGenericDrugDetails(item.drugDin);
		});

		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{
					text: this.$t('breadcrumb.drug', 'en'),
					to: {
						name: COVERAGE.DRUGS,
						params: {
							drugDin: this.drugDin,
							index: this.index
						}
					}
				},
				{ text: this.$t('breadcrumb.generic', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{
					text: this.$t('breadcrumb.drug', 'fr'),
					to: {
						name: COVERAGE.DRUGS,
						params: {
							drugDin: this.drugDin,
							index: this.index
						}
					}
				},
				{ text: this.$t('breadcrumb.generic', 'fr') }
			]
		);
		sessionStorage.setItem('drugDin', this.drugDin);
		sessionStorage.setItem('index', this.index);
	}

	async getGenericDrugDetails(drugDin) {
		this.$store.dispatch('updateLoading', true);

		let searchObject = {
			participant: this.participant,
			drugDin: drugDin,
			provinceOfAdjudication: this.province
		};

		// api call to get the details of each generic equivalents
		this.genericDrugDetails = await DrugCoverageGeneric.getGrugCoverageDetail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			searchObject
		);
		let drugContent = {
			drugName: '',
			drugDin: '',
			eligibility: '',
			tier: ''
		};
		drugContent.drugName = this.genericDrugDetails?.drugName;
		drugContent.drugDin = this.genericDrugDetails?.drugDin;
		drugContent.eligibility = this.genericDrugDetails?.eligibility;
		drugContent.tier = this.genericDrugDetails?.tier;
		drugContent.webCode = this.genericDrugDetails?.webCode;
		this.resultArray.push(drugContent);

		this.$store.dispatch('updateLoading', false);
	}

	getTier(tier) {
		return (this.tier = `${this.$t('tier')}` + tier);
	}

	isTier1(tier) {
		return tier === '1' ? true : false;
	}

	getDrugName(drugName) {
		if (drugName) {
			return titleCase(drugName);
		} else {
			return '';
		}
	}

	navigateBack() {
		this.$router.push({
			name: COVERAGE.DRUGS,
			params: {
				drugDin: this.drugDin,
				index: this.index
			}
		});
	}
}
</script>
<style lang="scss" scoped>
.title {
	font-family: $lato-font-family;
	font-size: 21px;
	margin-bottom: -15px;
}

.generic-drug-table {
	background: #ffffff;
	border-right: 1px solid $gray-light;
	border-top: 1px solid $gray-light;
	border-left: 1px solid $gray-light;
	&:last-child {
		border-bottom: 1px solid $gray-light;
	}
}

.drug-name {
	font-family: $josefin-font-family;
	font-size: 16px;
	word-break: break-word;
	@media (max-width: 767.99px) {
		margin-top: 10px;
	}
}

.din {
	font-size: 14px;
	font-family: $lato-font-family;
	margin-bottom: 17px;
	@media (max-width: 992px) {
		margin-bottom: 0px;
	}
}

.eligibility {
	font-family: $josefin-font-family;
	font-size: 15px;
	padding-top: 17px;
	padding-left: 30px;
	border-right: 1px solid $gray-light;
	border-left: 1px solid $gray-light;
	word-break: break-word;
	@media (max-width: 992px) {
		padding-top: 10px;
		width: 75%;
		border: none;
		padding-bottom: 10px;
	}
	@media (max-width: 374.99px) {
		padding-top: 10px;
		width: 66%;
		border: none;
		padding-bottom: 10px;
	}
}

.tier {
	font-family: $josefin-font-family;
	font-size: 15px;
	padding-top: 17px;
	text-align: center;
	@media (max-width: 992px) {
		padding-top: 10px;
		padding-bottom: 10px;
		border: none;
		width: 25%;
		text-align: right;
	}
	@media (max-width: 374.99px) {
		padding-top: 10px;
		padding-bottom: 10px;
		border: none;
		width: 34%;
		text-align: right;
	}
}

.tier ::v-deep .modal-content {
	text-align: left;
}

.back-button {
	font-size: 15px;
}
</style>
<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "myCoverage": "Plan Coverage",
      "drug": "Drugs",
      "generic": "Generic Equivalents"
    },
    "title": "Generic Equivalents",
    "text1": "Generic drugs have the same active ingredients in the same form as marketed brands but often cost less. Consult with your health professional to determine if a generic equivalent may work for you.",
    "din":"DIN: ",
    "tier": "Tier ",
		"tier1Tooltip":{
				"title": "Tier 1 - Higher Coinsurance - Plan Pays",
		"text": "Drugs in this tier have a higher coinsurance, which is the portion your plan pays—meaning you pay less out-of-pocket. These drugs are more cost-effective and follow widely accepted treatment guidelines for many acute and chronic conditions. This tier includes both the brand and its interchangeable generics."
		},
		"tier2Tooltip": {
			"title":"Tier 2 – Lower Coinsurance – Plan Pays",
			"text":"The drugs in this tier have a lower coinsurance, which is the portion your plan pays—meaning you could pay more out-of-pocket. These drugs typically have lower cost alternatives available on Tier 1 or are generally for less serious medical conditions. This tier includes both the brand and its interchangeable generics. Drugs not approved by MAP but required under the Régie de l’assurance maladie du Québec (RAMQ) are also included on this tier (applies to Quebec residents only)."
		},
		"button":{
			"back":"Back to results"
		}
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "myCoverage": "Couverture du régime",
      "drug": "Médicaments",
      "generic":"Équivalents génériques"
    },
    "title": "Équivalents génériques",
    "text1": "Les médicaments génériques contiennent les mêmes ingrédients actifs et ont la même forme médicamenteuse que le médicament de marque, mais souvent à moindre coût. Consultez un professionnel de la santé pour connaître le médicament générique qui vous convient le mieux.",
    "din":"DIN: ",
    "tier":"Niveau ",
			"tier1Tooltip":{
				"title": "Niveau 1 – Coassurance plus élevée – Remboursée par le régime",
		"text": "Pour les médicaments de ce niveau, le montant de coassurance (la portion des frais payée par votre régime) est plus élevé, ce qui signifie que vous avez moins d’argent à débourser. Ces médicaments offrent un meilleur rapport coût-efficacité et respectent les lignes directrices généralement reconnues relatives au traitement de bon nombre de maladies aiguës et chroniques. Ce niveau inclut les médicaments de marque et leurs versions génériques interchangeables."
		},
		"tier2Tooltip": {
			"title":"Niveau 2 – Coassurance moins élevée – Remboursée par le régime",
			"text":"Pour les médicaments de ce niveau, le montant de coassurance (la portion des frais payée par votre régime) est moins élevé, ce qui signifie que vous pourriez avoir plus d’argent à débourser. Ces médicaments ont généralement d’autres options à moindre coût au niveau 1 ou servent à traiter des maladies moins graves. Ce niveau inclut les médicaments de marque et leurs versions génériques interchangeables. Les médicaments qui ne sont pas approuvés par le GCM, mais qui sont requis par la Régie de l’assurance maladie du Québec (RAMQ), font également partie de ce niveau (s’applique aux résidents du Québec seulement)."
		},
		"button":{
			"back": "Retour aux résultats"
		}
  }
}
</i18n>
