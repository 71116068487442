<template>
	<BaseCard class="participant" :automation-id="getAutomationId('updateFormCard')">
		<BRow class="m-0">
			<BCol class="update-form-header" cols="12">
				<p class="h4">{{ title }}</p>
			</BCol>
			<BCol class="update-form-body" cols="12">
				<p class="small-body">{{ subTitle }}</p>
			</BCol>
			<BCol cols="12" class="p-0 update-form-button">
				<DownloadLink
					:label="buttonLabel"
					:icon="icon"
					:automation-id="getAutomationId('updateFormCard')"
					@click="HasClicked"
				/>
			</BCol>
		</BRow>
	</BaseCard>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BRow, BCol } from 'bootstrap-vue';
import BaseCard from '@/components/common/card/BaseCard';
import DownloadLink from '@/components/common/DownloadLink';
// @vue/component
@Component({
	name: 'UpdateFormCard',
	components: {
		BaseCard,
		BRow,
		BCol,
		DownloadLink
	},
	mixins: [IdMixin],
	props: {
		title: {
			type: String,
			default: ''
		},
		subTitle: {
			type: String,
			default: ''
		},
		buttonLabel: {
			type: String,
			default: ''
		},
		icon: {
			type: Array,
			default: () => ['fal', 'file-alt'],
			required: false
		},
		automationId: {
			type: String,
			default: '',
			required: true
		}
	}
})
export default class UpdateFormCard extends Vue {
	HasClicked() {
		this.$emit('click');
	}
}
</script>
<style lang="scss" scoped>
.small-body {
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
	font-size: $body-small-font-size-two;
}
.update-form-header {
	padding: 0px;
	margin-bottom: 10px;
	p {
		margin: 0px;
	}
}
.update-form-body {
	padding: 0px;
	margin-bottom: 20px;
	p {
		margin: 0px;
	}
}
.update-form-button {
	padding: 0px;
}
.update-form-button ::v-deep p {
	margin-bottom: 0px;
	padding: 0px;
	@media (min-width: 768px) {
		padding-bottom: 15px;
	}
}
::v-deep .btn {
	padding: 0px;
	margin: 0px;
	text-align: left;
	p {
		padding: 0px;
	}
}
::v-deep .card-body {
	padding-bottom: 2.1rem;
}
</style>
