<template>
	<div>
		<BaseButton
			:id="getUniqueId('backButton')"
			class="pl-0 mr-3 mt-3 back-button"
			:automation-id="getAutomationId('backButton')"
			:label="$t('button.back')"
			:aria-label="$t('button.back')"
			:icon="['fal', 'chevron-left']"
			icon-position="left"
			icon-size="sm"
			variant="link"
			@click="navigateBack()"
		></BaseButton>
		<p class="mt-2 title">{{ $t('title') }}</p>
		<CoverageTemplate :page-title="getDrugName(drugName)" full-width>
			<BRow class="pb-4 mb-4">
				<BCol lg="10" md="12">
					<p>{{ $t('text1') }}</p>
					<BaseAccordion v-if="comparableDrugs" automation-id="comparable-drugs">
						<BaseAccordionPanel
							v-for="(drug, index) in comparableDrugs"
							:id="`bcollapse-comparable-drug-${index}`"
							:key="index"
							group-name="comparableDrugsResult"
							:button-label="index"
							border
							automation-id="drug-coverage"
							@show="showComaparableDetails(drug, index)"
						>
							<div v-for="(element, indexElem) in drug" :key="'elem-' + indexElem">
								<div v-for="(row, rowIndex) in eligibilityOfCompDrug" :key="'row-' + rowIndex">
									<div v-if="element.drugDin === row.drugDin">
										<BRow class="comparable-drug-table">
											<BCol lg="5" md="12">
												<div class="drug-name mt-md-3 mt-sm-2" role="tab">{{ row.drugName }}</div>
												<div class="din">{{ $t('din') }}{{ row.drugDin }}</div></BCol
											>
											<BCol lg="4" md="8" sm="6" class="eligibility">
												<DrugEligibility :eligibility="row.eligibility" :web-code="row.webCode" />
											</BCol>
											<BCol lg="2" md="3" sm="5" class="tier">
												<div v-if="row.tier">
													<TextModal
														:text="getTier(row.tier)"
														:modal-title="
															isTier1(row.tier)
																? $t('tier1Tooltip.title')
																: $t('tier2Tooltip.title')
														"
														:automation-id="getAutomationId('textModal')"
														:id-increment="`-tier-${rowIndex}`"
													>
														<div v-if="row.tier === '1'">
															<p>{{ $t('tier1Tooltip.text') }}</p>
														</div>
														<div v-if="row.tier === '2'">
															<p>{{ $t('tier2Tooltip.text') }}</p>
														</div></TextModal
													>
												</div></BCol
											>
											<BCol lg="1" md="1" sm="1" class="generic">
												<TextModal
													v-if="row.isGeneric"
													:text="$t('generic')"
													:modal-title="$t('gToolTip.title')"
													:automation-id="getAutomationId('textModal')"
													:id-increment="`generic-${rowIndex}`"
												>
													<p>{{ $t('gToolTip.text') }}</p>
												</TextModal></BCol
											>
										</BRow>
									</div>
								</div>
							</div>
						</BaseAccordionPanel>
					</BaseAccordion>
				</BCol>
			</BRow>
		</CoverageTemplate>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
import CoverageTemplate from '../CoverageTemplate.vue';
import { BCol, BRow } from 'bootstrap-vue';
import DrugsSearchResult from '@/components/coverage/drugs/DrugsSearchResult';
import { titleCase } from '@/utils/titleCase';
import BaseButton from '@/components/common/base/BaseButton';
import BaseAccordion from '@/components/common/base/BaseAccordion';
import BaseAccordionPanel from '@/components/common/base/BaseAccordionPanel';
import DrugCoverageComparable from '@/models/coverage/DrugCoverageComparable.js';
import DrugEligibility from '@/components/coverage/drugs/DrugEligibility';
import TextModal from '@/components/common/TextModal.vue';

@Component({
	name: 'ComparableDrugsPage',
	components: {
		CoverageTemplate,
		BCol,
		BRow,
		BaseButton,
		DrugsSearchResult,
		BaseAccordionPanel,
		BaseAccordion,
		DrugEligibility,
		TextModal
	},
	mixins: [IdMixin, BreadcrumbsManager],
	watch: {
		locale: function () {
			/**
			 * When the locale changes the search drugs are displayed in the preferred language.
			 *the drug messages are retrieved from the api
			 */
			if (this.isDrugSelected) {
				this.dinsToGetEligibility = [];
				this.eligibilityOfCompDrug = null;
				this.getComp(this.searchObj);
			}
		}
	}
})
export default class ComparableDrugsPage extends Vue {
	comparableDrugs = null;
	drugName = null;
	province = null;
	participant = null;
	tier = null;
	drugOrDin = null;
	drugDin = null;
	drugs = null;
	index = null;
	eligibilityOfCompDrug = null;
	isDrugSelected = false;
	selectedDrug = null;
	searchObj = {};

	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	dinsToGetEligibility = [];

	async mounted() {
		this.comparableDrugs = this.$route.params.comparableDrugs;
		this.drugName = this.$route.params.drugName;
		this.province = this.$route.params.province;
		this.participant = this.$route.params.participant;
		this.drugOrDin = this.$route.params.drugOrDin;
		this.drugs = this.$route.params.drugs;
		this.index = this.$route.params.index;
		this.drugDin = this.$route.params.drugDin;

		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{
					text: this.$t('breadcrumb.drug', 'en'),
					to: {
						name: COVERAGE.DRUGS,
						params: {
							drugDin: this.drugDin,
							index: this.index
						}
					}
				},
				{ text: this.$t('breadcrumb.comparable', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{
					text: this.$t('breadcrumb.drug', 'fr'),
					to: {
						name: COVERAGE.DRUGS,
						params: {
							drugDin: this.drugDin,
							index: this.index
						}
					}
				},
				{ text: this.$t('breadcrumb.comparable', 'fr') }
			]
		);

		sessionStorage.setItem('drugDin', this.drugDin);
		sessionStorage.setItem('index', this.index);
	}

	async created() {
		if (this.isDrugSelected) {
			this.showComaparableDetails(this.selectedDrug);
		}
	}

	async showComaparableDetails(drug, index) {
		this.isDrugSelected = true;
		this.dinsToGetEligibility = [];
		this.selectedDrug = drug;
		this.eligibilityOfCompDrug = null;

		if (drug) {
			drug.forEach((item) => {
				this.dinsToGetEligibility.push(item.drugDin);
			});
		}

		let searchObject = {
			participant: this.participant,
			drugDins: this.dinsToGetEligibility,
			provinceOfAdjudication: this.province,
			dinOrName: this.drugOrDin,
			drugs: this.drugs,
			drugIndex: index,
			drugArray: drug
		};

		this.getComp(searchObject);
		this.searchObj = searchObject;
	}

	// this.comparableDrugs has all the fields except eligibility, so the call below returns the data ( drugDin, drugName, eligibility, isGeneric, tier) of a comparable drug needed to display.

	async getComp(searchObject) {
		this.eligibilityOfCompDrug = await DrugCoverageComparable.getComparableDetail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			searchObject
		);
	}

	getDrugName(drugName) {
		if (drugName) {
			return titleCase(drugName);
		} else {
			return '';
		}
	}

	getTier(tier) {
		return (this.tier = `${this.$t('tier')}` + tier);
	}

	isTier1(tier) {
		return tier === '1' ? true : false;
	}

	navigateBack() {
		this.$router.push({
			name: COVERAGE.DRUGS,
			params: {
				drugDin: this.drugDin,
				index: this.index
			}
		});
	}
}
</script>

<style lang="scss" scoped>
.back-button {
	font-size: 15px;
}

.title {
	font-family: $lato-font-family;
	font-size: 21px;
	margin-bottom: -15px;
}

.accordion {
	border-radius: 0px;
	font-size: 16px;
	& ::v-deep :last-child {
		border: none;
	}
	@media (max-width: 767.99px) {
		margin-left: -15px;
		margin-right: -15px;
	}
}
::v-deep .accordion-content {
	padding: 0 15px;
	margin-bottom: 15px;
}

.comparable-drug-table {
	background: #ffffff;
	border-right: 1px solid $gray-light;
	border-top: 1px solid $gray-light;
	border-left: 1px solid $gray-light;
	&:last-child {
		border-right: 1px solid $gray-light;
		border-top: 1px solid $gray-light;
		border-left: 1px solid $gray-light;
		border-bottom: 1px solid $gray-light;
	}
}

.drug-name {
	font-family: $josefin-font-family;
	font-size: 16px;
	word-break: break-word;
	@media (max-width: 767.99px) {
		margin-top: 10px;
	}
}

.din {
	font-size: 14px;
	font-family: $lato-font-family;
	margin-bottom: 17px;
	@media (max-width: 992px) {
		margin-bottom: 0px;
	}
}

.eligibility {
	font-family: $josefin-font-family;
	font-size: 15px;
	padding-top: 17px;
	padding-left: 30px;
	border-right: 1px solid $gray-light;
	border-left: 1px solid $gray-light;
	word-break: break-word;
	@media (max-width: 992px) {
		padding-top: 10px;
		width: 60%;
		border: none;
		padding-bottom: 10px;
	}
}

.tier {
	font-family: $josefin-font-family;
	font-size: 15px;
	padding-top: 17px;
	text-align: center;
	border-right: 1px solid $gray-light;
	@media (max-width: 992px) {
		padding-top: 10px;
		padding-bottom: 10px;
		border: none;
		width: 30%;
		text-align: right;
	}
}

.tier ::v-deep .modal-content {
	text-align: left;
}

.generic {
	font-family: $josefin-font-family;
	font-size: 15px;
	padding-top: 17px;
	text-align: center;
	@media (max-width: 992px) {
		padding-top: 10px;
		padding-bottom: 10px;
		border: none;
		width: 10%;
		text-align: right;
	}

	::v-deep .modal-content {
		text-align: left;
	}
}
</style>

<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "myCoverage": "Plan Coverage",
      "drug": "Drugs",
      "comparable": "Comparable Drugs"
    },
    "title": "Comparable Drugs",
    "text1": "Comparable drugs are other medications that are also used to treat the same condition or disease as the original drug you searched for. This list isn’t meant to replace medical advice, and you should consult with your health professional to decide what drug is best for you.",
    "din":"DIN: ",
    "tier": "Tier ",
    "tier1Tooltip":{
        "title": "Tier 1 - Higher Coinsurance - Plan Pays",
    "text": "Drugs in this tier have a higher coinsurance, which is the portion your plan pays—meaning you pay less out-of-pocket. These drugs are more cost-effective and follow widely accepted treatment guidelines for many acute and chronic conditions. This tier includes both the brand and its interchangeable generics."
    },
    "tier2Tooltip": {
      "title":"Tier 2 – Lower Coinsurance – Plan Pays",
      "text":"The drugs in this tier have a lower coinsurance, which is the portion your plan pays—meaning you could pay more out-of-pocket. These drugs typically have lower cost alternatives available on Tier 1 or are generally for less serious medical conditions. This tier includes both the brand and its interchangeable generics. Drugs not approved by MAP but required under the Régie de l’assurance maladie du Québec (RAMQ) are also included on this tier (applies to Quebec residents only)."
    },
    "button":{
      "back":"Back to results"
    },
    "generic": "G",
		"gToolTip": {
			"title":"Generic Equivalents",
			"text":"Generic drugs have the same active ingredients in the same form as marketed brands but often cost less. Consult with your health professional to determine if a generic equivalent may work for you."
		}
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "myCoverage": "Couverture du régime",
      "drug": "Médicaments",
      "comparable":"Médicaments similaires"
    },
    "title": "Médicaments similaires",
    "text1": "Les médicaments similaires sont d’autres médicaments qui peuvent être utilisés pour traiter la même maladie ou le même état que le médicament original recherché. Cette liste n’a pas pour objectif de remplacer un avis médical. Vous devriez consulter votre professionnel de la santé pour décider du médicament qui vous convient le mieux.",
    "din":"DIN: ",
    "tier":"Niveau ",
      "tier1Tooltip":{
        "title": "Niveau 1 – Coassurance plus élevée – Remboursée par le régime",
    "text": "Pour les médicaments de ce niveau, le montant de coassurance (la portion des frais payée par votre régime) est plus élevé, ce qui signifie que vous avez moins d’argent à débourser. Ces médicaments offrent un meilleur rapport coût-efficacité et respectent les lignes directrices généralement reconnues relatives au traitement de bon nombre de maladies aiguës et chroniques. Ce niveau inclut les médicaments de marque et leurs versions génériques interchangeables."
    },
    "tier2Tooltip": {
      "title":"Niveau 2 – Coassurance moins élevée – Remboursée par le régime",
      "text":"Pour les médicaments de ce niveau, le montant de coassurance (la portion des frais payée par votre régime) est moins élevé, ce qui signifie que vous pourriez avoir plus d’argent à débourser. Ces médicaments ont généralement d’autres options à moindre coût au niveau 1 ou servent à traiter des maladies moins graves. Ce niveau inclut les médicaments de marque et leurs versions génériques interchangeables. Les médicaments qui ne sont pas approuvés par le GCM, mais qui sont requis par la Régie de l’assurance maladie du Québec (RAMQ), font également partie de ce niveau (s’applique aux résidents du Québec seulement)."
    },
    "button":{
      "back": "Retour aux résultats"
    },
    "generic": "G",
			"gToolTip": {
			"title":"Équivalents génériques",
			"text":"Les médicaments génériques contiennent les mêmes ingrédients actifs et ont la même forme médicamenteuse que le médicament de marque, mais souvent à moindre coût. Consultez un professionnel de la santé pour connaître le médicament générique qui vous convient le mieux."
		}
  }
}
</i18n>
