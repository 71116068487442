/**
 * this function converts texts to title cases
 *
 */

export function titleCase(str) {
	return str.replace(/\w\S*/g, (word) => {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});
}
