<!--
 Accordion Panel for Drugs-- copied from base accordion panel
  -->
<template>
	<div class="accordion-panel px-3" :class="{ 'accordion-border': border }">
		<BaseButton
			:label="buttonLabel"
			:variant="buttonVariant"
			:class="{ 'text-left': isTextLeft, 'text-center': isTextCenter, 'text-right': isTextRight }"
			class="accordion-button px-0 w-100"
			:icon="icon"
			role="tab"
			:icon-rotation="iconRotation"
			:icon-position="iconPosition"
			icon-size="lg"
			:automation-id="getAutomationId('accordion')"
			@click="changeVisibility(!isAccordionVisible)"
		/>
		<slot name="additional-text"></slot>
		<BCollapse
			:id="id"
			v-bind="$attrs"
			role="tablist"
			class="accordion-content"
			:accordion="groupName"
			:visible="isAccordionVisible"
			:appear="appear"
			:data-test-automation-id="getAutomationId('accordion')"
			:data-tracking-id="getAutomationId('accordion')"
			@input="changeVisibility"
			@show="$emit('show')"
		>
			<slot></slot>
		</BCollapse>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id.js';
import { BCollapse } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton';

// @vue/component
@Component({
	name: 'DrugsAccordionPanel',
	components: {
		BCollapse,
		BaseButton
	},
	inheritedAttrs: false,
	mixins: [IdMixin],
	props: {
		/**
		 * From Bootstrap-vue: The name of the accordion group that this collapse belongs to
		 */
		groupName: {
			type: String,
			required: true
		},
		/**
		 * From Bootstrap-vue: When set, and prop 'visible' is true on mount, will animate on initial mount
		 */
		appear: {
			type: Boolean,
			default: false
		},
		/**
		 * From Bootstrap-vue: When 'true', expands the collapse
		 */
		visible: {
			type: Boolean,
			default: null
		},
		/**
		 * From Bootstrap-vue: 	Used to set the `id` attribute on the rendered content, and used as the base to generate any additional element IDs as needed
		 */
		id: {
			type: String,
			default: ''
		},
		/**
		 * Sets the button text
		 */
		buttonLabel: {
			type: String,
			default: ''
		},
		/**
		 * sets the variant for the button the default will be link
		 * as per design requires link variant mroe for accordions
		 */
		buttonVariant: {
			type: String,
			default: 'link'
		},
		/**
		 * icon associated with the button
		 */
		icon: {
			type: Array,
			default: () => ['fal', 'angle-up']
		},
		/**
		 * Decide which side to display the icon
		 */
		iconPosition: {
			type: String,
			default: 'left'
		},
		/**
		 * Sets if the accordion requires a border at the bottom
		 */
		border: {
			type: Boolean,
			default: false
		},
		textFloat: {
			type: String,
			default: 'left'
		},
		indexToShow: {
			type: Number,
			default: null
		},
		index: {
			type: Number,
			default: null
		},
		isFromDrugPage: {
			type: Boolean,
			default: false
		}
	}
})
export default class DrugsAccordionPanel extends Vue {
	isVisible = false;

	// keeps the accordion from result drug search page open
	async mounted() {
		if (this.isFromDrugPage) {
			let id = `bcollapse-${this.indexToShow}`;
			setTimeout(function () {
				document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
			}, 100);
		}
	}
	async created() {
		if (this.isFromDrugPage) {
			if (this.index === this.indexToShow) {
				this.isVisible = true;
			}
		} else {
			this.isVisible = false;
		}
	}

	get isTextLeft() {
		return this.textFloat === 'left' ? true : false;
	}

	get isTextCenter() {
		return this.textFloat === 'center' ? true : false;
	}

	get isTextRight() {
		return this.textFloat === 'right' ? true : false;
	}

	get isAccordionVisible() {
		return this.visible !== null ? this.visible : this.isVisible;
	}

	changeVisibility(visible) {
		if (this.visible !== null) {
			this.$emit('input', visible);
		} else {
			this.isVisible = visible;
		}
	}

	get iconRotation() {
		return this.isAccordionVisible ? null : 180;
	}
}
</script>
