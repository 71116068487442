<template>
	<div v-if="drugs">
		<!-- eligibility -->
		<DrugEligibility
			v-if="drugs.eligibility"
			:eligibility="drugs.eligibility"
			:web-code="drugs.webCode"
			class="pb-3"
		/>
		<!-- tier -->
		<div v-if="drugs.tier" automation-id="drug-coverage">
			<div v-if="drugs.tier === '1'" class="icon-message pb-3">
				<FontAwesomeIcon
					:class="'benefit-yes'"
					:icon="['fas', 'check-circle']"
					aria-hidden="true"
				/>
				<div>
					<span role="tab">{{ $t('tier1') }}</span>
					<InfoModal
						class="d-inline"
						automation-id="drug-coverage-tier-1"
						:modal-title="$t('tier1Tooltip.title')"
					>
						{{ $t('tier1Tooltip.text') }}
					</InfoModal>
				</div>
			</div>
			<div v-else class="icon-message pb-3">
				<FontAwesomeIcon
					:class="'benefit-special'"
					:icon="['fas', 'exclamation-triangle']"
					aria-hidden="true"
				/>
				<div>
					<span role="tab">{{ $t('tier2') }}</span>
					<InfoModal
						class="d-inline"
						automation-id="drug-coverage-tier-1"
						:modal-title="$t('tier2Tooltip.title')"
					>
						{{ $t('tier2Tooltip.text') }}
					</InfoModal>
				</div>
			</div>
		</div>
		<!-- Conditional Copay -->
		<div v-if="drugs.condCopay" class="icon-message pb-3">
			<FontAwesomeIcon
				:class="'benefit-special'"
				:icon="['fas', 'exclamation-circle']"
				aria-hidden="true"
			/>
			<div>
				<span role="tab">{{ $t('condCopay.title') }}</span>
				<InfoModal
					class="d-inline"
					automation-id="drug-coverage-cond-pay"
					:modal-title="$t('condCopay.title')"
				>
					{{ $t('condCopay.text') }}
				</InfoModal>
			</div>
		</div>
		<!-- Copay may vary -->
		<div v-if="drugs.copayVary" class="icon-message pb-3">
			<FontAwesomeIcon
				:class="'benefit-special'"
				:icon="['fas', 'exclamation-circle']"
				aria-hidden="true"
			/>
			<div>
				<span role="tab">{{ $t('copayVary.title') }}</span>
				<InfoModal
					class="d-inline"
					automation-id="drug-coverage-cond-pay"
					:modal-title="$t('copayVary.title')"
				>
					{{ $t('copayVary.text') }}
				</InfoModal>
			</div>
		</div>
		<!-- Maximum Allowable Cost -->
		<div v-if="drugs.hasMac" class="icon-message pb-3">
			<FontAwesomeIcon
				:class="'benefit-special'"
				:icon="['fas', 'exclamation-circle']"
				aria-hidden="true"
			/>
			<div>
				<span role="tab">{{ $t('hasMac.head') }}</span>
				<InfoModal
					class="d-inline"
					automation-id="drug-coverage-mac"
					:modal-title="$t('hasMac.title')"
				>
					{{ $t('hasMac.text') }}
				</InfoModal>
			</div>
		</div>
		<!-- RAMQ -->
		<div v-if="drugs.ramq" class="icon-message pb-3">
			<FontAwesomeIcon
				:class="'benefit-special'"
				:icon="['fas', 'exclamation-circle']"
				aria-hidden="true"
			/>
			<div>
				<span role="tab">{{ $t('ramq.title') }}</span>
				<InfoModal
					class="d-inline"
					automation-id="drug-coverage-ramq"
					:modal-title="$t('ramq.title')"
				>
					{{ $t('ramq.text') }}
				</InfoModal>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import InfoModal from '@/components/common/InfoModal';
import BaseModal from '@/components/common/base/BaseModal';
import DrugEligibility from '@/components/coverage/drugs/DrugEligibility';

@Component({
	name: 'DrugsIconsAndMessages',
	components: {
		BContainer,
		BRow,
		BCol,
		InfoModal,
		BaseModal,
		DrugEligibility
	},
	mixins: [IdMixin],
	props: {
		drugs: {
			type: Object,
			default: null
		}
	}
})
export default class DrugsIconsAndMessages extends Vue {}
</script>
<style lang="scss" scoped>
.benefit-special {
	color: #f78e1e;
	font-size: 15px;
	margin-right: 7px;
}

.benefit-yes {
	color: $blue;
	font-size: 15px;
	margin-right: 7px;
}

.icon-message {
	display: flex;
	margin-left: -17px;
}
</style>
<i18n>
{
  "en": {
		"tier1":"This is a tier 1 drug - this is a cost-effective choice",
		"tier1Tooltip":{
				"title": "Tier 1 - Higher Coinsurance - Plan Pays",
		"text": "Drugs in this tier have a higher coinsurance, which is the portion your plan pays—meaning you pay less out-of-pocket. These drugs are more cost-effective and follow widely accepted treatment guidelines for many acute and chronic conditions. This tier includes both the brand and its interchangeable generics."
		},
		"tier2":"This is a tier 2 drug - you may have less expensive options",
		"tier2Tooltip": {
			"title":"Tier 2 – Lower Coinsurance – Plan Pays",
			"text":"The drugs in this tier have a lower coinsurance, which is the portion your plan pays—meaning you could pay more out-of-pocket. These drugs typically have lower cost alternatives available on Tier 1 or are generally for less serious medical conditions. This tier includes both the brand and its interchangeable generics. Drugs not approved by MAP but required under the Régie de l’assurance maladie du Québec (RAMQ) are also included on this tier (applies to Quebec residents only)."
		},
		"condCopay":{
			"title":"Conditional Copay",
			"text":"This drug qualifies for Conditional Copay. Conditional Copay is an automation technique that is applied to certain therapeutic categories within the tiered formulary. When your pharmacist dispenses this drug, if our system confirms that you have tried the lower cost alternatives products from Tier 1, this drug will reimburse at your Tier 1 copay. If the system does not find the required Tier 1 drugs in your claims history, your drug will still be dispensed, but will only be reimbursed at your Tier 2 copay."
		}, 
		"copayVary":{
			"title":"Tier or Copay May Vary",
			"text":"Tier or co-pay may vary by plan. May not be available on all plans. Please refer to your member booklet."
		}, 
			"hasMac":{
				"head": "Maximum Allowable Cost",
				"title":"Maximum Allowable Cost",
				"text":"These drugs are part of our Maximum Allowable Cost (MAC) product. If your plan has adopted MAC, these drugs will pay at Tier 1 coinsurance, but reimbursement will be reduced to the price of the most cost-effective option within the therapeutic category."
		}, 
			"ramq":{
			"title":"RAMQ",
			"text":"Only eligible for reimbursement in the province of Quebec due to mandatory formulary legislation."
		}
    },
    "fr": {
		"tier1":"Ce médicament fait partie du niveau 1 - il constitue un choix économique.",
			"tier1Tooltip":{
				"title": "Niveau 1 – Coassurance plus élevée – Remboursée par le régime",
		"text": "Pour les médicaments de ce niveau, le montant de coassurance (la portion des frais payée par votre régime) est plus élevé, ce qui signifie que vous avez moins d’argent à débourser. Ces médicaments offrent un meilleur rapport coût-efficacité et respectent les lignes directrices généralement reconnues relatives au traitement de bon nombre de maladies aiguës et chroniques. Ce niveau inclut les médicaments de marque et leurs versions génériques interchangeables."
		},
		"tier2":"Ce médicament fait partie du niveau 2 - des options à moindre coût s’offrent peut-être à vous.",
		"tier2Tooltip": {
			"title":"Niveau 2 – Coassurance moins élevée – Remboursée par le régime",
			"text":"Pour les médicaments de ce niveau, le montant de coassurance (la portion des frais payée par votre régime) est moins élevé, ce qui signifie que vous pourriez avoir plus d’argent à débourser. Ces médicaments ont généralement d’autres options à moindre coût au niveau 1 ou servent à traiter des maladies moins graves. Ce niveau inclut les médicaments de marque et leurs versions génériques interchangeables. Les médicaments qui ne sont pas approuvés par le GCM, mais qui sont requis par la Régie de l’assurance maladie du Québec (RAMQ), font également partie de ce niveau (s’applique aux résidents du Québec seulement)."
		},
			"condCopay":{
			"title":"Niveau dynamique",
			"text":"Ce médicament est admissible au niveau dynamique, qui est une technique d’automatisation s’appliquant à certaines catégories thérapeutiques dans la liste à niveaux. Lorsque votre pharmacie exécute l’ordonnance de ce médicament et que le système indique que vous avez essayé les autres options à moindre coût du niveau 1, vous obtenez le remboursement du médicament en fonction de la quote-­part du niveau 1. Si le système ne détecte pas les médicaments du niveau 1 dans votre historique de demandes de règlement, le médicament vous sera quand même remis, mais sera remboursé selon la quote-part du niveau 2."
		}, 
			"copayVary":{
			"title":"Le niveau ou la quote-part peuvent varier",
			"text":"Le niveau ou la quote-part peuvent varier en fonction du régime et ne sont pas nécessairement offerts dans tous les régimes. Veuillez consulter la brochure des adhérents."
		}, 
			"hasMac":{
				"head": "Prix maximum admissible",
				"title":"Prix admissible maximum",
				"text":"Ces médicaments font partie de l’option Prix maximum admissible. lls seront remboursés selon la quote-part de niveau 1 si votre régime offre l’option Prix maximum admissible. Cependant, le remboursement sera réduit au prix du médicament offrant le meilleur coût-efficacité dans la catégorie thérapeutique."
		},
			"ramq":{
			"title":"RAMQ",
			"text":"Ces médicaments sont admissibles au remboursement au Québec seulement, en raison de la réglementation sur les médicaments obligatoires."
		}
    }
}
</i18n>
